const menuButton = document.querySelector(".menu-button");
const closeButton = document.querySelector(".close-button");
const menuOverlay = document.querySelector(".menu-overlay");
const modalFrame = document.getElementById('modalFrame');
const listItems = document.querySelectorAll('[data-path]');
const infoSection = document.querySelector(".info");
const main = document.querySelector("main");
const header = document.querySelector("header");
const copyYear = document.querySelector(".copy-year");

const date = new Date().getFullYear();
copyYear.innerHTML = date;

/* Scroll */
window.onscroll = function showNav() {
  if (infoSection) {
    const infoSectionTop = infoSection.getBoundingClientRect().top;
    if (infoSectionTop <= 100) {
      header.classList.add("header-visible");
    } else {
      header.classList.remove("header-visible");
    }
    const scrollPercentage = (window.scrollY - infoSection.offsetTop + infoSection.scrollHeight) / infoSection.scrollHeight;
    const opacity = scrollPercentage < 0 ? 0 : (scrollPercentage / 3);
    main.style.background = "linear-gradient(rgba(0, 0, 0, " + opacity + "), rgba(0, 0, 20, " + opacity + "))";
  }
};

menuButton.addEventListener("click", _ => {
  const isOpen = menuButton.getAttribute('aria-expanded') === "true"
  menuButton.setAttribute('aria-expanded', !isOpen);
  menuOverlay.classList.add("open");
  setTimeout(_ => { closeButton.focus(); }, 100);
  bodyScrollLock.disableBodyScroll(menuOverlay);
});

closeButton.addEventListener("click", _ => {
  const isOpen = menuButton.getAttribute('aria-expanded') === "true"
  menuButton.setAttribute('aria-expanded', !isOpen);
  menuOverlay.classList.remove("open");
  bodyScrollLock.enableBodyScroll(menuOverlay);
});

// Hide list on keydown Escape
menuOverlay.addEventListener('keyup', e => {
  if (e.code === 'Escape') {
    menuButton.setAttribute('aria-expanded', false);
  }
});

function loadModal(path, e) {
  fetch(path)
    .then(response => response.text())
    .then(text => {
      modalFrame.innerHTML = text;
      bodyScrollLock.disableBodyScroll(modalFrame);
      const closeModalButton = document.querySelector(".modal .close-button");
      setTimeout(_ => { closeModalButton.focus(); }, 100);
      closeModalButton.addEventListener("click", closeModal);
      closeModalButton.addEventListener('keyup', e => {
        if (e.code === 'Escape') {
          closeModal();
        }
      });
      const posterImg = document.querySelector(".poster-img");
      if (posterImg) {
        const imgSrc = e.target.currentSrc
        const imgName = imgSrc.substring(imgSrc.lastIndexOf('/') + 1);
        const newName = imgSrc.replace(imgName, `details/${imgName}`);
        posterImg.src = newName;
      }
      modalFrame.classList.add('modal-visible');
    });
}

function closeModal() {
  modalFrame.innerHTML = '';
  modalFrame.classList.remove('modal-visible');
  bodyScrollLock.enableBodyScroll(modalFrame);
}

listItems.forEach(item => {
  const path = item.dataset.path;
  if (path) {
    item.addEventListener("click", (e) => { loadModal(path, e) })
  }
})
